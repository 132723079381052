<template>
  <div>
    <UiButton
      v-if="!timeslot.is_signed_up"
      variant="primary"
      :disabled="signingUp"
      @click="attendEvent(event.title, timeslot)"
    >
      Direct aanmelden
    </UiButton>
    <span v-else>Je bent al aangemeld</span>
  </div>
</template>

<script setup lang="ts">
import {EventDocument, type Event, type Timeslot, useCreateAttendeeMutation} from '~/graphql/graphql';
import {ModalEvent} from '#components';
import {notifyGraphQLError} from '~/composables/vue3-notification';

const props = defineProps<{
  event: Event,
  timeslot: Timeslot,
}>();

const signingUp = ref(false);

const modal = useModal();

const emit = defineEmits<{
  submitted: [],
}>();

function openModal(title: string, timeslot: Timeslot): Promise<{ success: boolean, formData: {guests: [], account_manager: ''} }> {
  const {promise, resolve} = Promise.withResolvers();

  modal.open({
    component: ModalEvent,
    props: {title, timeslot},
    events: {
      'confirm': (formData: object) => resolve({success: true, formData}),
      'close': () => resolve({success: false}),
    },
  });

  return promise;
}

async function attendEvent(title: string, timeslot: Timeslot) {
  const modalOutput = await openModal(title, timeslot);

  if (modalOutput.success){
    const {mutate, onDone, onError} = useCreateAttendeeMutation({
      variables: {
        timeslot_id: String(timeslot.id),
        guests: modalOutput.formData.guests,
        account_manager: modalOutput.formData.account_manager,
      },
      refetchQueries: [
        'attendees',
        {
          query: EventDocument,
          variables: {slug: props.event.slug},
        },
      ],
      onQueryUpdated(observableQuery) {
        return observableQuery.refetch();
      },
    });

    signingUp.value = true;
    mutate();

    onDone(() => {
      notify('Je bent succesvol aangemeld voor dit evenement.');
      signingUp.value = false;
      emit('submitted');
    });

    onError((error) => {
      notifyGraphQLError(error);
      signingUp.value = false;
    });
  }
}
</script>
