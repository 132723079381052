<template>
  <Dialog
    class="relative z-50"
    :open="true"
    @close="modal.close"
  >
    <div
      class="fixed inset-0 bg-black/70 backdrop-blur-sm"
      aria-hidden="true"
    />
    <div class="fixed inset-0 flex w-screen items-center justify-center p-4">
      <UiCard
        variation="white"
        class="max-h-full w-full overflow-auto lg:max-w-3xl"
      >
        <DialogPanel class="rounded-2xl p-0">
          <div class="flex items-center justify-between ">
            <button
              type="button"
              class="ml-auto flex items-center rounded-lg bg-primary p-2"
              @click="modal.close"
            >
              <Icon
                name="mdi:close"
                class="size-8"
              />
            </button>
          </div>
          <DialogTitle class="my-4">
            <slot name="title">
              {{ title }}
            </slot>
          </DialogTitle>
          <DialogDescription>
            <slot name="description">
              {{ description }}
            </slot>
          </DialogDescription>
          <slot />
        </DialogPanel>
      </UiCard>
    </div>
  </Dialog>
</template>

<script setup lang="ts">
import {
  Dialog,
  DialogPanel,
  DialogTitle,
  DialogDescription,
} from '@headlessui/vue';

defineProps<{
  type: 'destructive' | 'input'
  description?: string
  title: string
}>();

const modal = useModalInstance();
</script>
